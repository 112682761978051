import { render, staticRenderFns } from "./MiniWishlist.vue?vue&type=template&id=7d76a54f&"
import script from "./MiniWishlist.vue?vue&type=script&lang=js&"
export * from "./MiniWishlist.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "MiniWishlist.vue"
export default component.exports